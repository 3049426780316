import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    state: String
  }

  connect() {
    this.setCheckbox(this.stateValue);
  }

  setCheckbox(state) {
    if (state == 'indeterminate') {
      this.element.indeterminate = true;
    }
  }
}

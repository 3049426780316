document.addEventListener("turbo:load", function () {
  // Activate Bootstrap upgrade popovers
  const popovers = $('[data-toggle="upgrade_popover"]');
  if (popovers.length) {
    popovers.each(function() {
      const $this = $(this);
      const upgrade_link_name = $this.data('upgrade-link-name') || 'See plans'
      const upgrade_link_href = $this.data('upgrade-link-href') || '#'
      $this.popover({
        container: $this.data('popover-container') || $this,
        trigger: 'hover',
        placement: $this.data('placement') || 'auto',
        delay: {'hide': 200},
        title: 'Upgrade Needed',
        content: 'You need a different plan that supports this feature.',
        template: `
          <div class="popover" role="tooltip">
            <div class="arrow"></div>
            <h3 class="mb-3"><i class="far fa-arrow-alt-circle-up text-upgrade mr-2"></i> <span class="popover-header"></span></h3>
            <div class="popover-body"></div>
            <a href="${upgrade_link_href}" class="btn btn-sm btn-block btn-upgrade mt-3">${upgrade_link_name}</a>
          </div>
        `
      });
    });
  }
});

document.addEventListener("turbo:before-cache", function () {
  // Dispose of Bootstrap upgrade popovers
  const popovers = $('[data-toggle="upgrade_popover"]');
  if (popovers.length) {
    popovers.popover('dispose');
  }
});

import EasyRedirColours from './colorschemes.easyredir'
import { color as colorHelper } from 'chart.js/helpers'
import numbro from "numbro"

numbro.zeroFormat('0')

export const fonts = {
  base: "'Avenir Next', sans-serif"
}

export const colors = {
  brand: {
    green: '#28ba9b',
    red: '#c91134',
    orange: '#ffa800',
    yellow: '#ffd202'
  },
  gray: {
    footerContrast: '#33333c',
    dark: '#4c4c4c',
    mid: '#696e76',
    light: '#dadada',
    ultralight: '#f7f7f7'
  },
  highlight: '#ba2891',
  muted: '#696e76',
  black: '#29313c',
  white: '#FFFFFF',
  transparent: 'transparent'
}

export function initializeChartDefaults() {
  if (Chart) {
    applyOptions(Chart, defaultOptions)
    addColorSchemesPlugin()
  }
}

function applyOptions(parent, options) {
  for (let item in options) {
    if (typeof options[item] !== 'object' || Array.isArray(options[item])) {
      parent[item] = options[item];
    } else {
      applyOptions(parent[item], options[item]);
    }
  }
}

function addColorSchemesPlugin() {
  const helpers = Chart.helpers;

  Chart.defaults.plugins.colorschemes = {
    fillAlpha: 0.3,
    reverse: false
  }

  Chart.register({
    id: 'colorschemes',

    beforeUpdate: function(chart, _args, options) {
      const scheme = EasyRedirColours.green_scheme
      const length = scheme.length
      let colorIndex, color;

      // Set scheme colors
      chart.config.data.datasets.forEach(function(dataset, datasetIndex) {
        colorIndex = datasetIndex % length;
        color = scheme[options.reverse ? length - colorIndex - 1 : colorIndex];

        // Object to store which color option is set
        dataset.colorschemes = {};

        switch (dataset.type || chart.config.type) {
          // For line, radar and scatter chart, borderColor and backgroundColor (50% transparent) are set
          case 'line':
          case 'radar':
          case 'scatter':
            if (typeof dataset.backgroundColor === 'undefined') {
              dataset.backgroundColor = colorHelper(color).alpha(options.fillAlpha).rgbString();
              dataset.colorschemes.backgroundColor = true;
            }
            if (typeof dataset.borderColor === 'undefined') {
              dataset.borderColor = color;
              dataset.colorschemes.borderColor = true;
            }
            if (typeof dataset.pointBackgroundColor === 'undefined') {
              dataset.pointBackgroundColor = colorHelper(color).alpha(options.fillAlpha).rgbString();
              dataset.colorschemes.pointBackgroundColor = true;
            }
            if (typeof dataset.pointBorderColor === 'undefined') {
              dataset.pointBorderColor = color;
              dataset.colorschemes.pointBorderColor = true;
            }
            break;
          // For doughnut and pie chart, backgroundColor is set to an array of colors
          case 'doughnut':
          case 'pie':
            if (typeof dataset.backgroundColor === 'undefined') {
              dataset.backgroundColor = dataset.data.map(function(data, dataIndex) {
                colorIndex = dataIndex % length;
                return scheme[options.reverse ? length - colorIndex - 1 : colorIndex];
              });
              dataset.colorschemes.backgroundColor = true;
            }
            break;
          // For the other chart, only backgroundColor is set
          default:
            if (typeof dataset.backgroundColor === 'undefined') {
              dataset.backgroundColor = Array(dataset.data.length).fill(color);
              dataset.colorschemes.backgroundColor = true;
            }
            break;
        }
      });
      return true;
    },

    afterUpdate: function(chart) {
      // Unset colors
      chart.config.data.datasets.forEach(function(dataset) {
        if (dataset.colorschemes) {
          if (dataset.colorschemes.backgroundColor) {
            delete dataset.backgroundColor;
          }
          if (dataset.colorschemes.borderColor) {
            delete dataset.borderColor;
          }
          if (dataset.colorschemes.pointBackgroundColor) {
            delete dataset.pointBackgroundColor;
          }
          if (dataset.colorschemes.pointBorderColor) {
            delete dataset.pointBorderColor;
          }
          delete dataset.colorschemes;
        }
      });
    },
  })
}

function defaultDoughnutPieOptions(cutoutPercentage = 50) {
  return {
    cutout: `${cutoutPercentage}%`,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (items) {
            return items[0].label
          },
          label: function (context) {
            let value = context.parsed
            let content = ''

            if (!isNaN(value)) {
              const valuesSum = context.dataset.data.reduce((sum, value) => {
                return sum + value;
              }, 0)
              const percentage = value / valuesSum

              value = numbro(percentage).format({
                output: "percent",
                mantissa: 1
              })
            }

            content += `<span class="popover-body-value">${value}</span>`;
            return content;
          }
        }
      },
      legendCallback: function (chart) {
        const data = chart.data;
        let content = '';

        data.labels.forEach(function (label, index) {
          const colours = EasyRedirColours.green_scheme
          const colorIndex = index % colours.length
          const bgColor = colours[colorIndex]

          content += '<span class="chart-legend-item">';
          content += `<i class="chart-legend-indicator" style="background-color: ${bgColor}"></i>`;
          content += label;
          content += '</span>';
        });

        return content;
      }
    }
  }
}

const defaultOptions = {
  defaults: {
    responsive: true,
    maintainAspectRatio: false,
    color: colors.muted,
    font: { family: fonts.base, size: 13 },
    layout: {
      padding: 0
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 16
        }
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
        external: function (context) {
          const chart = context.chart;
          const tooltipModel = context.tooltip;
          const options = tooltipModel.options;

          // Get tooltip
          let $tooltip = $('#chart-tooltip');

          // Create tooltip on first render
          if (!$tooltip.length) {
            $tooltip = $('<div id="chart-tooltip" class="popover popover-chart bs-popover-top" role="tooltip"></div>');

            // Append to body
            $('body').append($tooltip);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            $tooltip.css('display', 'none');
            return;
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Fill with content
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            let html = '';
            let periodDescription = '';

            // Add arrow
            html += '<div class="arrow"></div>';

            // Add header
            titleLines.forEach(function (title) {
              periodDescription = title;
              html += `<h3 class="popover-header text-center">${title}</h3>`;
            });

            const align = (bodyLines.length > 1) ? 'justify-content-left' : 'justify-content-center';

            // Add body
            bodyLines.forEach(function (body, i) {
              const zeroValue = $(body[0]).filter('.popover-body-label-0').length > 0
              const colors = tooltipModel.labelColors[i];
              const styles = `background-color: ${colors.backgroundColor}`;
              const indicator = !options.hideIndicator ? `<span class="popover-body-indicator" style="${styles}"></span>` : '';
              html += `<div class="popover-body ${zeroValue ? 'popover-body-zero' : ''} d-flex align-items-center ${align}">${indicator}${body}</div>`;
            });

            // Calculate sum of all data at this dataPoint.dataIndex
            if (options.showFooterSummary) {
              let dataPointIndexSum = 0;
              tooltipModel.dataPoints.forEach(function(dataPoint) {
                chart.data.datasets.forEach((dataset) => {
                  dataPointIndexSum += dataset.data[dataPoint.dataIndex]
                })
              });
              const footerLabel = `<span class="popover-body-label mr-auto">Total on ${periodDescription}</span>`;
              const footerSum = `<span class="popover-body-value">${numbro(dataPointIndexSum).format({thousandSeparated: true})}</span>`
              html += `<div class="popover-body popover-footer d-flex align-items-center ${align}">${footerLabel}${footerSum}</div>`;
            }

            $tooltip.html(html);
          }

          // Get tooltip position
          const $canvas = $(chart.canvas);

          const canvasWidth = $canvas.outerWidth();
          const canvasHeight = $canvas.outerHeight();

          const canvasTop = $canvas.offset().top;
          const canvasLeft = $canvas.offset().left;

          const tooltipWidth = $tooltip.outerWidth();
          const tooltipHeight = $tooltip.outerHeight();

          const top = canvasTop + tooltipModel.caretY - tooltipHeight - 16;
          const left = canvasLeft + tooltipModel.caretX - tooltipWidth / 2;

          // Display tooltip
          $tooltip.css({
            'top': top + 'px',
            'left': left + 'px',
            'display': 'block',
          });

        },
        callbacks: {
          label: function (item) {
            const label = item.dataset.label || '';
            let yLabel = item.formattedValue;
            let content = '';

            if (item.chart.data.datasets.length > 1) {
              content += `<span class="popover-body-label text-truncate popover-body-label-${yLabel} mr-auto">${label}</span>`;
            }

            if (!isNaN(yLabel)) {
              yLabel = numbro(yLabel).format({
                thousandSeparated: true
              })
            }

            content += `<span class="popover-body-value popover-body-value-${yLabel}">${yLabel}</span>`;
            return content;
          }
        }
      },
    },
    datasets: {
      bar: {
        maxBarThickness: 20,
      }
    },
    elements: {
      point: {
        radius: 2,
        borderWidth: 1,
        hoverRadius: 2,
        pointStyle: 'circle',
        backgroundColor: colors.brand.green
      },
      line: {
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderCapStyle: 'rounded'
      },
      bar: {
        borderRadius: 0
      },
      arc: {
        borderColor: colors.white,
        borderWidth: 3
      }
    },
    animation: {
      duration: 600
    },
    scales: {
      linear: {
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: colors.gray.light,
          drawBorder: false,
          drawTicks: false,
          lineWidth: 0,
          zeroLineWidth: 0,
          zeroLineColor: colors.gray.light,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2]
        },
        ticks: {
          beginAtZero: true,
          padding: 10,
          callback: function (value) {
            if (!(value % 10)) {
              return value
            }
          },
          stepSize: undefined
        }
      },
      category: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          padding: 20
        },
        maxBarThickness: 20
      }
    }
  },
  overrides: {
    doughnut: defaultDoughnutPieOptions(70),
    pie: defaultDoughnutPieOptions(0),
  }
}

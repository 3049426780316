import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "actionsForm", 'actionsMenuButton', 'actionsWorkingMessage' ];

  submit(event) {
    event.preventDefault();

    let $actionLink = $(event.currentTarget);
    this.setActionOnActionsForm($actionLink);
    this.setMethodOnActionsForm($actionLink);
    this.setWorkingMessage();
    this.actionsFormTarget.submit();
  }

  setActionOnActionsForm($actionLink) {
    this.actionsFormTarget.action = $actionLink.data('form-action');
  }

  setMethodOnActionsForm($actionLink) {
    let formMethod = $actionLink.data('form-method');

    if (['delete', 'put', 'patch'].includes(formMethod)) {
      this.actionsFormTarget.method = 'post';
      this.actionsFormTarget.insertAdjacentHTML('afterbegin', `<input type="hidden" name="_method" value="${formMethod}" />`)
    } else {
      this.actionsFormTarget.method = formMethod
    }
  }

  setWorkingMessage() {
    if (this.hasActionsMenuButtonTarget) {
      this.actionsMenuButtonTarget.disabled = true;
    }
    if (this.hasActionsWorkingMessageTarget) {
      this.actionsWorkingMessageTarget.textContent = 'One moment please...';
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer";
import Rails from '@rails/ujs'
import { postJSON } from "./controller_helpers"

export default class extends Controller {
  static targets = ["button", "link"]
  static values = {
    organization: String,
    url: String,
    token: String,
    exportType: String
  }

  connect() {
    this.subscription = this.subscribe(this.organizationValue)
  }

  disconnect() {
    this.subscription.consumer.disconnect();
  }

  subscribe(id) {
      return consumer.subscriptions.create(
      {
        channel: "ExportsChannel",
        id: id 
      },
      {
        received: this._received.bind(this),
      }
    );
  }

  startDownload(context) {
    // prevent default handler
    context.preventDefault();

    // disable the button until complete
    context.target.classList.add("disabled");
    this.start(context.target.dataset.url, this.exportTypeValue)
        .then((data) => this.handleNewExport(data));
    return false;
  }

  async start(url, type) {
    return postJSON(url, {type: type})
  }

  handleNewExport(data) {
    const url = new URL(this.urlValue);
    url.searchParams.set('token', data.token);
    this.tokenValue = data.token;
    this.linkTarget.setAttribute("href", url);
    this.linkTarget.click();
  }

  _received(data) {
    // reset token
    if (data.token == this.tokenValue) {
      Rails.enableElement(this.buttonTarget);
      this.buttonTarget.classList.remove("disabled");
      this.linkTarget.setAttribute("href", "#");
    }
  }
}

import RedirectEntryController from "./redirect_entry_controller"

export default class extends RedirectEntryController {
  static targets = [ 
    'bulkInput'
  ];

  syncSourceUrl(e) {
    if (e.detail.content) {
      this.bulkInputTarget.value = e.detail.content;
    }
  }

  show() {
    this.bulkInputTarget.disabled = false;
  }

  hide() {
    this.bulkInputTarget.disabled = true;
  }
}

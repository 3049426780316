import ApplicationController from './application_controller';
import { httpRequest } from './controller_helpers';
import { getChargebeeInstance, unmountChargebeeInstance } from 'chargebee/index.js.erb';

export default class extends ApplicationController {
  static values = {
    sessionCreateUrl: String,
    organizationAnalyticsProperties: Object,
    analyticsIntegrations: Object,
  };

  connect() {
    this.chargebee = getChargebeeInstance();

    document.addEventListener("turbo:before-cache", () => {
      unmountChargebeeInstance();
    }, { once: true })
  }

  showPaymentHistory(event) {
    event.stopPropagation();
    event.preventDefault();

    this.chargebee.setPortalSession(() => {
      return httpRequest('POST', this.sessionCreateUrlValue, {});
    });

    this.chargebee.createChargebeePortal().openSection({
      sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
    });

    this.trackEvent(
      'Billing History Viewed',
      this.organizationAnalyticsPropertiesValue,
      { integrations: this.analyticsIntegrationsValue }
    );
  }
}

import { Controller } from "@hotwired/stimulus"
import moment from 'moment'

export default class extends Controller {
  static targets = ["datepicker"];
  static values = {
    endDate: String,
    minDate: String,
    period: String,
    startDate: String,
    includeToday: { type: Boolean, default: false }, // default is to exclude today
    supportPeriods: { type: Boolean, default: true } // default is to include month or day period selection.
  }

  initialize() {
  }

  connect() {
    const start = moment(this.startDateValue);
    const end = moment(this.endDateValue);
    const minDate = moment(this.minDateValue || '2021-04-01');
    const today = moment();
    const period = Math.abs(start.diff(today, 'days')) > 90 ? "month" : this.periodValue;
    const supportPeriods = this.supportPeriodsValue

    const self = this;

    const ranges = {
        'Last 7 Days': [moment().subtract(7, 'days'), this.endDate(), 'day'],
        'Last 30 Days': [moment().subtract(30, 'days'), this.endDate(), 'day'],
        'Last 90 Days': [moment().subtract(90, 'days'), this.endDate(), 'day'],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'day'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'), 'day']
    }

    if (moment().diff(minDate, "days") < 90) {
      delete ranges['Last 90 Days'];
    }

    $(this.datepickerTarget).daterangepicker({
      opens: 'right',
      autoUpdateInput: false,
      startDate: start,
      endDate: end,
      minDate: minDate,
      maxDate: (period === "month" ? moment().subtract(1, 'month').endOf('month') : this.endDate()),
      period: period,
      supportPeriods: supportPeriods,
      periodLabel: 'View by:',
      ranges: ranges
    }, (start, end) => { self.setDate(start, end); });

    $(this.datepickerTarget).on('showCalendar.daterangepicker', this.addShadow); 

    this.setDate(start, end);
  }

  disconnect() {
    $(this.datepickerTarget).off('showCalendar.daterangepicker', this.addShadow);
  }

  endDate() {
    return this.includeTodayValue ? moment().endOf('day') : moment().subtract(1, 'days').endOf('day');
  }

  addShadow(_evt, picker) {
    picker.container.addClass('shadow');
  }

  // Visit the current url with params from
  // the calendar
  updateRange(event) {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));

    params.set('start', event.detail.startDate.startOf('day').unix());
    params.set('end', event.detail.endDate.startOf('day').unix());
    params.set('p', event.detail.period);
    params.delete('page'); // remove page because new range may have less pages
    Turbo.visit(url.pathname + '?' + params.toString());
  }

  setDate(start, end) {
    this.datepickerTarget.value = start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY');
  }
}

import debounce from 'lodash/debounce'

document.addEventListener("turbo:load", function () {
  // Imitate change event on contenteditable
  // elements. This is used to allow for stimulus
  // event handling change events.
  $('body').on('focus', '[contenteditable]', function() {
      const $this = $(this);
      $this.data('before', $this.html());
  }).on('keypress', '[contenteditable]', function(e) {
    if (e.keyCode == 13 || e.which == 13) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.currentTarget.dispatchEvent(new Event('enter'));
    }
  }).on('blur keyup paste input', '[contenteditable]', debounce(function(e) {
      const $this = $(this);
      if ($this.data('before') !== $this.html()) {
          $this.data('before', $this.html());
          $this[0].dispatchEvent(new Event('change'));
      }
  }, 500));
});

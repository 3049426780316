import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 
    "bulkCheckOn",
    "bulkEntry", 
    "singleEntry", 
  ]

  toggleBulk(event) {
     this.setBulk(event.currentTarget.checked);
  }

  setBulk(bulk_enabled) {
    if (bulk_enabled) {
      this.dispatch('bulkEnabled', { detail: {} })
    } else {
      this.dispatch('bulkDisabled', { detail: {} })
    }

    this.singleEntryTarget.hidden = bulk_enabled;
    this.bulkEntryTarget.hidden = !bulk_enabled;
  }

  bulkSourceChanged(e) {
    this.bulkCheckOnTarget.disabled = e.detail.content.split(/[\n\r]/)?.length > 1;
  }
}

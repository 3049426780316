// Generated using http://paletton.com/
'use strict';

export default {
  // Primary brand monochromatic colour schemes
  green_mono: ['#28BA99', '#76DDC6', '#49CAAD', '#05AE88', '#008467'],
  red_mono: ['#C91135', '#EF627E', '#DE3959', '#A20423', '#7D0019'],
  orange_mono: ['#FFA41B', '#FFC671', '#FFB547', '#D47F00', '#A76400'],
  yellow_mono: ['#FFD202', '#FFE464', '#FFDC3A', '#C7A300', '#9C8100'],

  // Various secondary colours based off of the primary EasyRedir green colour
  green_secondary1: ['#356EBD', '#80A7DE', '#5587CC', '#1456B1', '#0B3F87'],
  green_secondary2: ['#FFB437', '#FFD288', '#FFC25C', '#FFA207', '#CD8000'],
  green_secondary3: ['#F43553', '#FA8598', '#F75972', '#F2072C', '#C2001F'],
  green_complimentary: ['#FF8437', '#FFB688', '#FF9B5C', '#FF6607', '#CD4E00'],

  // Various full schemes based off of the primary EasyRedir green colour
  green_tetrad: ['#28BA99', '#76DDC6', '#49CAAD', '#05AE88', '#008467', '#356EBD', '#80A7DE', '#5587CC', '#1456B1', '#0B3F87', '#FFB437', '#FFD288', '#FFC25C', '#FFA207', '#CD8000', '#FF8437', '#FFB688', '#FF9B5C', '#FF6607', '#CD4E00'],
  green_scheme: ['#28BA99', '#356EBD', '#FFB437', '#F43553', '#FF8437', '#76DDC6', '#80A7DE', '#FFD288', '#FA8598', '#FFB688', '#49CAAD', '#5587CC', '#FFC25C', '#F75972', '#FF9B5C', '#05AE88', '#1456B1', '#FFA207', '#F2072C', '#FF6607', '#008467', '#0B3F87', '#CD8000', '#C2001F', '#CD4E00']
};

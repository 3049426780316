import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parent"];

  connect() {
    const inputElements = this.parentTarget.querySelectorAll("input, textarea, button, select")
    inputElements.forEach((inputElement) => {
      inputElement.disabled = true
      inputElement.setAttribute("tabindex", "-1")
      inputElement.setAttribute("aria-disabled", true)

      const labelElement = inputElement.parentElement.querySelector("label.form-control-label")
      if (labelElement !== null) {
        labelElement.classList.add("disabled")
      }
    })
  }
}

import { render, cancel, register } from 'timeago.js';

const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;
const SECONDS_IN_DAY = HOURS * MINUTES * SECONDS;

const enFormatFunc = (number, index, totalSec) => {
  // Between 105s - 120s, round up to 2 minutes
  // Won’t work for already mounted components because won’t update between 60-120s
  if (index === 2 && totalSec >= 105) {
    return ['2 minutes ago', 'in 2 minutes'];
  }

  // 1-6 days ago should be based on actual days of the week (from 0:00 - 23:59)
  if (index === 6 || index === 7) {
    // Calculate seconds since midnight for right now
    const now = new Date();
    const secondsSinceMidnight =
      now.getSeconds() + (now.getMinutes() * SECONDS) + (now.getHours() * MINUTES * SECONDS);

    // Subtract seconds since midnight from totalSec, divide by seconds in a day, round down
    // Result is off-by-one number of days since datetime (unless time was at midnight)
    const daysFloored = Math.floor((totalSec - secondsSinceMidnight) / SECONDS_IN_DAY);
    // If time was at midnight (00:00), it will divide evenly with SECONDS_IN_DAY
    // That will make it count as from the previous day, which we do not want
    const remainder = (totalSec - secondsSinceMidnight) % SECONDS_IN_DAY;
    const days = remainder >= 1 ? daysFloored + 1 : daysFloored;
    const noun = days === 1 ? 'day' : 'days';
    return [`${days} ${noun} ago`, `in ${days} ${noun}`];
  }

  // For 9-12 days ago, Convert “1 week ago” to “__ days ago”
  // For 13 days, round it to “2 weeks ago”
  if (index === 8) {
    const days = Math.round(totalSec / SECONDS / MINUTES / HOURS);
    if (days > 8) {
      return days === 13
        ? ['2 weeks ago', 'in 2 weeks']
        : [`${days} days ago`, `in ${days} days`];
    }
  }

  return [
    ['just now', 'right now'],
    ['%s seconds ago', 'in %s seconds'],
    ['1 minute ago', 'in 1 minute'],
    ['%s minutes ago', 'in %s minutes'],
    ['1 hour ago', 'in 1 hour'],
    ['%s hours ago', 'in %s hours'],
    ['1 day ago', 'in 1 day'],
    ['%s days ago', 'in %s days'],
    ['1 week ago', 'in 1 week'],
    ['%s weeks ago', 'in %s weeks'],
    ['1 month ago', 'in 1 month'],
    ['%s months ago', 'in %s months'],
    ['1 year ago', 'in 1 year'],
    ['%s years ago', 'in %s years']
  ][index];
}

register('en_US', enFormatFunc)

document.addEventListener("turbo:load", function() {
  let nodes = document.querySelectorAll('time.js-relative-datetime')
  if (nodes.length > 0) {
    render(nodes)
  }
})

document.addEventListener("turbo:before-cache", function() {
  cancel()
})

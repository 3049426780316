import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "country", "provinceState", "provinceStateContainer" ];

  initialize() {
    this.$provinceStateOptionGroups = $($(this.provinceStateTarget).html())
  }

  connect() {
    this.filterProvinceStates(this.countryTarget.value);
    this.triggerProvinceStateChangeEvent();
  }

  countrySelected() {
    this.filterProvinceStates(this.countryTarget.value);
    this.clearProvinceStateValue();
    this.triggerProvinceStateChangeEvent();
  }

  filterProvinceStates(country) {
    this.$provinceState.prop('disabled', false);

    switch(country) {
      case 'CA':
        this.showProvinceStateContainer();
        this.$provinceState.html(this.prependEmptyOption(this.$provinceStateOptionGroups.filter("optgroup[label=Canada]")).html());
        break;
      case 'US':
        this.showProvinceStateContainer();
        this.$provinceState.html(this.prependEmptyOption(this.$provinceStateOptionGroups.filter("optgroup[label=USA]")).html());
        break;
      case 'AU':
        this.showProvinceStateContainer();
        this.$provinceState.html(this.prependEmptyOption(this.$provinceStateOptionGroups.filter("optgroup[label=Australia]")).html());
        break;
      default:
        this.hideProvinceStateContainer();
        this.$provinceState.html('<option value=""></option>');
        this.$provinceState.prop('disabled', true);
        break;
    }
  }

  prependEmptyOption(options) {
    return options.prepend($("<option>", {value: '', text: ''}))
  }

  showProvinceStateContainer() {
    if (this.hasProvinceStateContainerTarget) {
      $(this.provinceStateContainerTarget).collapse('show')
    }
  }

  hideProvinceStateContainer() {
    if (this.hasProvinceStateContainerTarget) {
      $(this.provinceStateContainerTarget).collapse('hide')
    }
  }

  clearProvinceStateValue() {
    this.$provinceState.val([])
  }

  triggerProvinceStateChangeEvent() {
    this.$provinceState.trigger('change')
  }

  get $provinceState() {
    return $(this.provinceStateTarget)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "container"];

  connect() {
    this.evaluateInput()
  }

  evaluateInput() {
    let value = this.inputTarget.value

    for (let container of this.containerTargets) {
      let containerForValue = container.dataset.conditionalVisibilityContainerFor
      let containerForValuesArray = containerForValue.split('|||')

      if (containerForValuesArray.includes(value)) {
        $(container).collapse('show')
      } else {
        $(container).collapse('hide')
      }
    }
  }
}
